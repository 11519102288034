// Main javascript entry point
'use strict';

var $ = require('jquery');

var Popup = require('../_modules/popup/popup');
var Header = require('../_modules/header/header');
var AOS = require('aos');

var Swiper = require('swiper');
var Inputmask = require('inputmask');

var select2 = require('select2');
select2($);
window.swipers = {}

$(function() {
  const popup = new Popup();
  const header = new Header();

  popup.init();


  var list = $(this).parents('.team').find('.team__list');
  var elemPaste = list.find('.team__item').first().clone()
  
  // $('#link-add').bind().on('click', function(){
  //   var list = $(this).parents('.team').find('.team__list');
  //   var elemPaste = list.find('.team__item').eq(0).clone()
  //   var elemPaste2 = list.find('.team__item').eq(1).clone()
  //   var elemPaste3 = list.find('.team__item').eq(2).clone()

  //   list.append(elemPaste3);
  //   list.append(elemPaste);
  //   list.append(elemPaste2);
  // })

  // $('#link-add2').bind().on('click', function(){
  //   var list = $(this).parents('.news').find('.news__list');
  //   var elemPaste = list.find('.news__item').eq(0).clone()
  //   var elemPaste2 = list.find('.news__item').eq(1).clone()
  //   var elemPaste3 = list.find('.news__item').eq(2).clone()

  //   list.append(elemPaste3);
  //   list.append(elemPaste);
  //   list.append(elemPaste2);
  // })
  
  $('.js-example-basic-single').select2({
    theme: 'classic',
    allowClear: true,
    minimumResultsForSearch: Infinity
  });

  window.swipers.mySwiper = new Swiper ('.welcome__i--t1 .swiper-container', {
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: { crossFade: true },
    navigation: {
      nextEl: '.welcome__i--t1 .click'
    },
  })

  window.swipers.mySwiper2 = new Swiper ('.welcome__i--t2 .swiper-container', {
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: { crossFade: true },
    navigation: {
       nextEl: '.welcome__i--t2 .click'
    },
  })

  AOS.init({
    once: true,
    mobile: false 
  });

  var selector = document.getElementsByClassName('js-number');
  Inputmask({"mask": "+375 99 999-99-99", "placeholder": "X", 'showMaskOnHover': false}).mask(selector);

  $('.js-night').unbind().on('click', function(){
    $('body').addClass('body--transition')
    setTimeout(toggleAnimation, 1000);

    $('body').toggleClass('night')
  })

  function toggleAnimation(){
    $('body').toggleClass('body--transition')
  }

});
