'use strict';

const $ = require('jquery');
// Constructor
var Header = function() {

  function hoverItem(item) {
    item.hover(
      function() {

        $(this).parent().toggleClass('hover')

      }, function() {

      }
    );
  }

  hoverItem($('.header-navigation__text'));
  // hoverItem($('.header-navigation__pic'));

};

module.exports = Header;
