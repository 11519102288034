'use strict';

const $ = require('jquery');
const fixScrollClass = 'body--overflow';
const popupClass = 'popup';
const popupOpenClass = 'popup--open';

// Constructor
var Popup = function() {
  this.name = 'popup';
  this.isOpen = false;
  console.log('%s module', this.name.toLowerCase());

  function getPopupName() {
    const name = location.hash || '';
    return name.replace('#', '');
  }

  this.updatePopupStatus = function() {
    const popupName = getPopupName();
    const thisPopup = popupName ? $('#' + popupName) : $();
    const allPopups = $('.' + popupClass);

    const body = $('body');
    this.isOpen = popupName && thisPopup.length;

    if (this.isOpen) {
      body.addClass(fixScrollClass);
      thisPopup.addClass(popupOpenClass);
    } else {
      allPopups.removeClass(popupOpenClass);
      body.removeClass(fixScrollClass);
    }
  }

  this.init = function() {
    this.updatePopupStatus();
    $(window).on('hashchange', this.updatePopupStatus.bind(this));
  }
};


module.exports = Popup;
